.vms-vehicle-delete-modal {
  .ant-radio-group {
    .ant-radio-wrapper {
      span {
        font: normal normal 400 16px/24px 'Work Sans', sans-serif !important;
      }
    }
  }

  .delete-vehicle-body {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .warning-message {
      font: normal normal 500 16px/24px 'Work Sans', sans-serif !important;
    }

    .warning {
      color: #e86056;
    }

    .vehicle-info {
      margin-bottom: 10px;
      font: normal normal 400 16px/24px 'Work Sans', sans-serif !important;

      span {
        font-weight: 500;
      }
    }
  }
}
