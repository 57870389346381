.violationSpeed {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 8px !important;

  .eiq-column__text {
    color: #e86056 !important;
  }
}

.citation-info-column {
  @media screen and (max-width: 540px) {
    .eiq-column__title {
      font: normal normal 400 18px/20px 'Work Sans', sans-serif;
    }
    .eiq-column__text {
      font: normal normal 400 13px/18px 'Work Sans', sans-serif;
    }
  }

  .citation-tags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      margin-bottom: 2px;

      .eiq-tag {
        font-size: 13px;
      }
    }
  }
}
